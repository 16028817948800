<template>
  <div>
    <KTCodePreview title="Trạng thái đồng bộ với CMS">
      <template v-slot:preview>
        <div>
          <b-row class="mb-5">
            <ErrorChart :chartData="chartData"></ErrorChart>
          </b-row>
          <b-row class="mb-5">
            <b-col md="3">
              <b-input
                placeholder="Tìm kiếm theo ID tham chiếu"
                v-model="apiParams.referenceId"
                size="sm"
                autocomplete="off"
              ></b-input>
            </b-col>
            <b-col md="3">
              <b-form-select
                v-model="selectedModule"
                :options="moduleListOption"
                size="sm"
              >
              </b-form-select>
            </b-col>
            <b-col md="3">
              <b-form-select
                v-model="selectedStatus"
                :options="statusOptions"
                size="sm"
              >
              </b-form-select>
            </b-col>

            <b-col md="3">
              <b-button size="sm" variant="primary" @click="fetchData">
                Tìm kiếm
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col md="3">
              <b-form-select
                v-model="selectedExceptionFrom"
                :options="exceptionFromList"
                size="sm"
              >
              </b-form-select>
            </b-col>
          </b-row>
          <b-table
            :items="syncStatusList"
            :fields="fields"
            bordered
            hover
            :busy="isLoading"
            :total-rows="totalItems"
            :current-page="currentPage"
            :per-page="0"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="20"></vcl-table>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>

            <template #cell(id)="row">
              <div
                class="show-error-detail text-primary font-weight-bold"
                @click="showDetailError(row.item.id)"
              >
                Chi tiết
              </div>
            </template>

            <template #cell(action)="row">
              <span v-if="row.item.isResolved === 0">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="reSync(row.item.id)"
                  >Đồng bộ lại</b-button
                >
              </span>
            </template>

            <template #cell(isResolved)="row">
              <span
                v-if="row.item.isResolved === 1"
                class="label label-success label-inline font-weight-bold"
                >{{ statusName.resolved }}</span
              >
              <span
                v-else
                class="label label-danger label-inline font-weight-bold"
                >{{ statusName.notResolved }}</span
              >
            </template>

            <template #cell(moduleName)="row">
              <span
                ><b>{{ row.item.moduleName }}</b></span
              >
              <br />
              <span>({{ row.item.module }})</span>
            </template>

            <template #cell(fullResponse)="row">
              <span
                id="row.item.id"
                v-b-tooltip.hover.bottom
                v-b-tooltip.hover="{ variant: 'dark' }"
                :title="row.item.fullResponse"
                >{{ readMore(row.item.fullResponse) }}</span
              >
            </template>

            <template #cell(fullRetryResponse)="row">
              <span
                id="row.item.id"
                v-b-tooltip.hover.bottom
                v-b-tooltip.hover="{ variant: 'dark' }"
                :title="row.item.fullRetryResponse"
                >{{ readMore(row.item.fullRetryResponse) }}</span
              >
            </template>
          </b-table>

          <b-row>
            <b-col>
              <p>Tổng số: {{ formatPrice(totalItems) }}</p>
            </b-col>
            <b-col>
              <b-pagination-nav
                v-model="currentPage"
                v-show="totalPage >= 2"
                :number-of-pages="totalPage"
                @change="handlePageChange"
                use-router
                :link-gen="linkGen"
                first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                last-class="page-item-last btn btn-icon btn-sm my-1 "
                page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                align="right"
              >
                <template v-slot:first-text>
                  <span>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </span>
                </template>

                <template v-slot:prev-text>
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
                </template>

                <template v-slot:next-text>
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
                </template>

                <template v-slot:last-text>
                  <span class="text-info">
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </span>
                </template>
              </b-pagination-nav>
            </b-col>
          </b-row>
        </div>

        <b-modal ref="error-detail-modal" hide-footer title="Chi tiết lỗi">
          <v-form>
            <div>
              <b-form-group
                label="Đường dẫn:"
                label-for="input-url"
                label-class="font-weight-bold"
              >
                {{ previewDetail.url }}
              </b-form-group>

              <b-form-group
                label="Phương thức"
                label-for="input-method"
                label-class="font-weight-bold"
              >
                {{ previewDetail.method }}
              </b-form-group>

              <b-form-group
                label="Thời gian tạo"
                label-class="font-weight-bold"
              >
                {{ previewDetail.createdAt }}
              </b-form-group>

              <b-form-group
                label="Thời gian cập nhật"
                label-class="font-weight-bold"
              >
                {{ previewDetail.updatedAt }}
              </b-form-group>

              <b-form-group
                label="Đầu vào"
                label-for="input-json"
                label-class="font-weight-bold"
              >
                <pre id="input-json">{{ previewDetail.payload }}</pre>
              </b-form-group>

              <b-form-group
                label="Kết quả lần đầu"
                label-class="font-weight-bold"
              >
                {{ previewDetail.fullResponse }}
              </b-form-group>

              <b-form-group
                label="Kết quả thử lại"
                label-class="font-weight-bold"
              >
                {{ previewDetail.fullRetryResponse }}
              </b-form-group>

              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                @click="hideDetailErrorModal"
                >Đóng</b-button
              >
            </div>
          </v-form>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { VclTable } from 'vue-content-loading';
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import { SideBar } from '@/core/config/menu/sideBar';
import ApiService from '../../../core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { formatDate, currencyMask, formatPrice } from '../../../utils/common';
import { checkPermission } from '../../../utils/saveDataToLocal';
import timeUtils from '@/utils/date';
import ErrorChart from './ErrorChart.vue';

export default {
  components: {
    KTCodePreview,
    VclTable,
    ErrorChart,
  },
  data() {
    return {
      chartData: null,
      dpConfigs: null,
      apiParams: {
        fromDate: null,
        toDate: null,
        referenceId: null,
      },
      syncStatusList: [],
      previewDetail: {
        payload: null,
        url: null,
        errResponse: null,
        method: null,
        createdAt: null,
        updatedAt: null,
        fullRetryResponse: null,
        fullResponse: null,
      },
      fields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '2%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'errorId',
          label: 'ID Lịch sử',
          thStyle: { textAlign: 'center', width: '2%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'moduleName',
          label: 'Tên module',
          thStyle: { textAlign: 'center', width: '4%' },
          tdClass: 'text-left align-middle',
        },
        {
          key: 'referenceId',
          label: 'Id tham chiếu',
          thStyle: { textAlign: 'center', width: '3%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'id',
          label: 'Chi tiết',
          thStyle: { textAlign: 'center', width: '3%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'fullResponse',
          label: 'Kết quả lần đầu',
          thStyle: { textAlign: 'center', width: '6%' },
          tdClass: 'text-left align-middle',
        },
        {
          key: 'fullRetryResponse',
          label: 'Kết quả thử lại',
          thStyle: { textAlign: 'center', width: '6%' },
          tdClass: 'text-left align-middle',
        },
        {
          key: 'isResolved',
          label: 'Trạng thái',
          thStyle: { textAlign: 'center', width: '3%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'createdAt',
          label: 'Thời gian tạo',
          thStyle: { textAlign: 'center', width: '4%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'updatedAt',
          label: 'Lần cuối cập nhật',
          thStyle: { textAlign: 'center', width: '4%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'action',
          label: 'Hành động',
          thStyle: { width: '4%', textAlign: 'center' },
          tdClass: 'text-center align-middle',
        },
      ],
      statusOptions: [
        {
          value: null,
          text: '-- Lọc theo trạng thái --',
        },
        {
          value: 1,
          text: 'Thành công',
        },
        {
          value: 0,
          text: 'Thất bại',
        },
      ],
      exceptionFromList: [
        {
          value: null,
          text: '-- Lọc nguồn gây lỗi -- ',
        },
        {
          value: 0,
          text: 'Không xác định',
        },
        {
          value: 1,
          text: 'Core',
        },
        {
          value: 2,
          text: 'CMS',
        },
      ],
      selectedExceptionFrom: null,
      selectedStatus: null,
      selectedModule: null,
      moduleListOption: [
        { value: null, text: '-- Lọc theo module --' },
        { value: 'order_new', text: 'Tạo mới đơn hàng' },
        { value: 'order_update', text: 'Cập nhật đơn hàng' },
        { value: 'customer_new', text: 'Tạo mới khách hàng' },
        { value: 'customer_update', text: 'Cập nhật khách hàng' },
        {
          value: 'product_attribute_new',
          text: 'Tạo mới thuộc tính sản phẩm',
        },
        {
          value: 'product_attribute_update',
          text: 'Tạo mới thuộc tính sản phẩm',
        },
        { value: 'category_new', text: 'Tạo mới danh mục' },
        { value: 'category_update', text: 'Chỉnh sửa danh mục' },
        { value: 'customer_point_upsert', text: 'Đồng bộ điểm khách hàng' },
        {
          value: 'customer_point_history_new',
          text: 'Đồng bộ lịch sử tích điểm',
        },
        { value: 'product_new', text: 'Tạo mới sản phẩm' },
        { value: 'product_update', text: 'Cập nhật sản phẩm' },
        { value: 'product_combo_new', text: 'Tạo mới sản phẩm combo' },
        { value: 'product_combo_update', text: 'Cập nhật sản phẩm combo' },
        { value: 'free_accessory_new', text: 'Tạo BPK tặng kèm' },
        { value: 'free_accessory_update', text: 'Cập nhật BPK tặng kèm' },
        { value: 'promotion_accessory_new', text: 'Tạo BPK khuyến mãi' },
        {
          value: 'promotion_accessory_update',
          text: 'Cập nhật BPK khuyến mãi',
        },
        {
          value: 'trade_in_program_new',
          text: 'Tạo mới chương trình thu cũ',
        },
        {
          value: 'trade_in_program_update',
          text: 'Cập nhật chương trình thu cũ',
        },
        { value: 'trade_in_import_new', text: 'Tạo mới phiếu thu cũ' },
        { value: 'trade_in_import_update', text: 'Cập nhật phiếu thu cũ' },
        { value: 'discount_new', text: 'Tạo mới chương trình chiết khấu' },
        {
          value: 'discount_update',
          text: 'Cập nhật chương trình chiết khấu',
        },
        {
          value: 'update_evaluation_status',
          text: 'Đồng bộ trạng thái phiếu định giá thu cũ',
        },
        { value: 'warranty_package_new', text: 'Tạo gói bảo hành' },
        { value: 'warranty_package_update', text: 'Cập nhật gói bảo hành' },
      ],
      statusName: {
        resolved: 'Succeed',
        notResolved: 'Failed',
      },
      isLoading: false,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      sellingPrice: null,
      mask: currencyMask,
      isSearching: false,
      isModalSearching: false,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      totalPage: null,
      date: new Date(),
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.PRODUCT);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Trạng thái đồng bộ với CMS', route: '/cms-sync-monitor' },
    ]);
  },
  created() {
    const initPage = this.$route.query.page;
    this.currentPage = initPage ? initPage : 1;
    this.fetchData();
    this.initialConstant();
    this.fetchStatistics();
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    initialConstant() {
      this.dpConfigs = timeUtils.DP_CONFIG;
    },
    formatPrice,
    formatDate,
    fetchStatistics() {
      ApiService.get(`/cms-sync/statistics`).then((response) => {
        this.chartData = response.data.data;
      });
    },
    fetchData() {
      this.isLoading = true;
      this.syncStatusList = [];

      let queryList = [];
      if (this.selectedStatus !== null) {
        queryList.push(`isResolved=${this.selectedStatus}`);
      }

      if (this.selectedExceptionFrom !== null) {
        queryList.push(`exceptionFrom=${this.selectedExceptionFrom}`);
      }

      if (this.selectedModule != null) {
        queryList.push(`module=${this.selectedModule}`);
      }

      if (this.apiParams.referenceId !== null) {
        queryList.push(`referenceId=${this.apiParams.referenceId}`);
      }

      queryList.push(`limit=${this.perPage}`);
      queryList.push(`page=${this.currentPage}`);

      const query = `?${queryList.join('&')}`;

      ApiService.get(`/cms-sync/sync-error-list${query}`).then((response) => {
        const data = response.data.data;
        this.syncStatusList = data.data;

        // pagination
        this.totalItems = data.total;
        this.currentPage = data.currentPage;
        this.totalPage = data.totalPage;

        this.isLoading = false;
      });
    },
    checkPermission,
    showDetailErrorModal(id) {
      const detail = this.syncStatusList.find((item) => item.id === id);
      console.log(detail);

      this.previewDetail.payload = detail.payload;
      this.previewDetail.url = detail.url;
      this.previewDetail.errResponse = detail.errResponse;
      this.previewDetail.method = detail.method;
      this.previewDetail.createdAt = detail.createdAt;
      this.previewDetail.updatedAt = detail.updatedAt;
      this.previewDetail.fullResponse = detail.fullResponse;
      this.previewDetail.fullRetryResponse = detail.fullRetryResponse;

      this.$refs['error-detail-modal'].show();
    },
    hideDetailErrorModal() {
      this.$refs['error-detail-modal'].hide();
    },
    showDetailError(id) {
      this.showDetailErrorModal(id);
    },
    reSync(id) {
      ApiService.get(`/cms-sync/resync/${id}`)
        .then(() => {
          this.isLoading = true;
          this.fetchData();
          this.isLoading = false;
        })
        .catch(() => {});
    },
    readMore(text) {
      if (text && text.length > 200) {
        return text.slice(0, 200) + ' ...';
      }
      return text;
    },
  },
};
</script>

<style scoped>
.show-error-detail:hover {
  text-decoration: underline;
  cursor: pointer;
}

.add-button {
  font-size: 1rem;
}

.checkbox-label {
  display: block;
}

.test {
  position: absolute;
  right: 1vw;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
}
</style>
